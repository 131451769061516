<template>
  <div
    class="fill-height"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <o-loader v-if="loading" />
      <v-tabs
        v-else
        v-model="category"
        vertical
        background-color="primary"
        class="fill-height"
      >
        <v-tab
          v-for="(category, i) of categories"
          :key="`category-${i}`"
        >
          <div
            class="fill-width text-left"
          >
            <v-icon
              left
              v-text="category.icon"
            />
            <span class="d-none d-sm-inline">
              {{ $t('messages.views.admin.configuration.parameters.categories.' + category.name) }}
            </span>
          </div>
        </v-tab>

        <v-tabs-items
          v-model="category"
          class="fill-height"
        >
          <v-card
            flat
            class="fill-height"
          >
            <o-form
              ref="form"
              class="fill-height"
              :loading="saving"
              @submit="submit"
            >
              <v-card-text>
                <v-tab-item
                  v-for="(category, i) of categories"
                  :key="`category-${i}-items`"
                >
                  <v-card-title>
                    {{ $t('messages.views.admin.configuration.parameters.categories.' + category.name) }}
                  </v-card-title>

                  <template v-for="(name, j) of category.parameters">
                    <v-row
                      :key="`category-${i}-parameter-${j}`"
                      class="align-center"
                    >
                      <v-col
                        class="text-center text-md-right col-12 col-md-8 col-lg-7 col-xl-6"
                      >
                        <div class="font-weight-bold">
                          {{ $t('constants.parameter.' + name + '.name') }}
                        </div>
                        <div class="text-caption">
                          {{ $t('constants.parameter.' + name + '.description') }}
                        </div>
                      </v-col>

                      <v-col class="col-12 col-md-4 col-lg-4 col-xl-3 d-flex justify-center">
                        <o-text-field
                          v-if="parameters[name].type === 'string'"
                          v-model="form[name]"
                          full-width
                          hide-label
                          :label="'constants.parameter.' + name + '.name'"
                          :readonly="!!parameters[name].readonly"
                          :rules="{required: true}"
                        />
                        <o-textarea
                          v-if="parameters[name].type === 'json'"
                          v-model="form[name]"
                          full-width
                          hide-label
                          filled
                          :label="'constants.parameter.' + name + '.name'"
                          :readonly="!!parameters[name].readonly"
                          :rules="parameters[name].rules"
                        />
                        <o-switch
                          v-if="parameters[name].type === 'bool'"
                          v-model="form[name]"
                          color="secondary"
                          :rules="parameters[name].rules"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="j < category.parameters.length - 1"
                      :key="`divider-${j}`"
                    >
                      <v-divider />
                    </v-row>
                  </template>
                </v-tab-item>
              </v-card-text>

              <o-submit
                absolute
                bottom
                right
              >
                {{ $t('actions.save') }}
              </o-submit>
            </o-form>
          </v-card>
        </v-tabs-items>
      </v-tabs>
    </transition>
  </div>
</template>

<script>
  import Vue from 'vue';

  export default {
    apollo: {
      parameters: {
        query: require('@gql/views/admin/configuration/parameters/getParameters.gql'),
        client: 'customer',
        fetchPolicy: 'no-cache',
        result (res, key) {
          this.loading = res.loading;
        },
        update (data) {
          const parameters = {};
          this.form = {};
          for (const parameter of data.getParameters) {
            parameter.value = this.getParameterScalarValue(parameter);
            parameter.rules = this.getParameterValidationRules(parameter);

            parameters[parameter.name] = parameter;
            Vue.set(this.form, parameter.name, parameter.value);
          }

          return parameters;
        },
      },
    },

    data: () => ({
      loading: true,
      saving: false,
      form: null,
      category: null,
      categories: [
        {
          icon: 'mdi-cog',
          name: 'general',
          parameters: [
            'CUSTOMER_NAME',
            'CITY_BOUNDARIES',
          ],
        },
        {
          icon: 'mdi-cloud-download-outline',
          name: 'trafficData',
          parameters: [
            'TRAFFIC_DATA_ENABLE',
          ],
        },
        {
          icon: 'mdi-traffic-light',
          name: 'trafficControl',
          parameters: [
            'TRAFFIC_CONTROL_ENABLE',
          ],
        },
        {
          icon: 'mdi-information',
          name: 'publicDashboard',
          parameters: [
            'PUBLIC_DASHBOARD_ENABLE',
            'PUBLIC_DASHBOARD_DISPLAY_TRAFFIC',
            'PUBLIC_DASHBOARD_DISPLAY_INFORMATIONS',
          ],
        },
      ],
    }),

    methods: {
      submit () {
        this.saving = true;
        const input = [];
        for (const name of Object.keys(this.form)) {
          if (!this.parameters[name].readonly) {
            input.push({
              name: name,
              value: this.convertScalarToParameterValue(this.form[name], this.parameters[name]),
            });
          }
        }

        this.$apollo.mutate({
          mutation: require('@gql/mutations/parameter/updateParameters.gql'),
          client: 'customer',
          fetchPolicy: 'no-cache',
          variables: {
            input: input,
          },
        }).then((response) => {
          this.$flash('messages.views.admin.configuration.parameters.submit.success', 'success');
          this.$refs.form.reset();
          this.$store.dispatch('app/loadParameters');
        }).catch((e) => {
          this.$flash('messages.views.admin.configuration.parameters.submit.error', 'error');
        }).finally(() => {
          this.saving = false;
        });
      },

      getParameterValidationRules (parameter) {
        const rules = {};

        if (!parameter.nullable) {
          rules.required = true;
        }

        return rules;
      },

      getParameterScalarValue (parameter) {
        switch (parameter.type) {
          case 'json':
            return JSON.stringify(parameter.value, null, 2);
          default:
            return parameter.value;
        }
      },

      convertScalarToParameterValue (scalar, parameter) {
        switch (parameter.type) {
          case 'json':
            return JSON.parse(scalar);
          default:
            return scalar;
        }
      },
    },
  };
</script>

<style scoped lang="sass">
  .v-tab
    text-transform: none !important

  .v-tabs-items
    ::v-deep .v-window__container,
    ::v-deep .v-window-item
      height: 100% !important
</style>
